import React from "react";
import Profile from '../asset/profile.jpg'

const About = () => {
    return (
        <section id="about" className="py-20 bg-gray-900">
            <div className="container mx-auto text-center px-6">
                <h2 className="text-4xl font-bold mb-8 text-gray-50">
                    Tentang saya
                </h2>

                <div className="flex flex-col items-center mb-12">
                    <img src={`${Profile}`} alt="Profile Picturee" className="w-32 h-32 rounded-full object-cover mb-4 shadow-lg"/>
                    <p className="text-lg text-gray-100 mb-2"> Studen & Sofware Engineer</p>
                    <p className="text-xl text-gray-500 front-semibold">I’m and Sofware engineer Intern In KIDI Telkom educourser</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                <div className="bg-gray-800 p-6 rounded-lg shadow-lg hover:bg-gray-600 transition-colors duration-300">
                    <h3 className="text-2x1 font-bold mb-4 text-gray-950">ReactJS</h3>
                    <p className="text-gray-50 mb-4">
                    pengguna web dan native. Bangun antarmuka pengguna dari bagian-bagian individual yang disebut komponen yang ditulis dalam JavaScript 
                    </p>

                </div>      <div className="bg-gray-800 p-6 rounded-lg shadow-lg hover:bg-gray-600 transition-colors duration-300">
                    <h3 className="text-2x1 font-bold mb-4 text-gray-950">UI/UX</h3>
                    <p className="text-gray-50 mb-4">
                    Desain Antarmuka Pengguna ( UI ) dan Pengalaman Pengguna ( UX ) memegang peranan penting dalam pengalaman yang diperoleh pengguna saat berinteraksi dengan produk dan aplikasi digital. Dalam kursus ini, kami akan membahas teori dan metodologi di balik desain UI dan UX . Anda juga akan mendesain wireframe dan prototipe interaktif Anda sendiri.
                    </p>
                </div>

                <div className="bg-gray-800 p-6 rounded-lg shadow-lg hover:bg-gray-600 transition-colors duration-300">
                    <h3 className="text-2xl font-bold mb-4 text-gray-950">Node JS</h3>
                    <p className="text-gray-50 mb-4">
                    alat yang populer untuk hampir semua jenis proyek! Node.js menjalankan mesin JavaScript V8, inti dari Google Chrome, di luar browser. Hal ini memungkinkan Node.js menjadi sangat berkinerja.
                     </p>
                    </div>
                </div>
                <p className="text-lg text-gray-50 mt-8">
                    I do also provide a coding mentoring for a competitions and projects mentoring
                </p>
            </div>
        </section>
    )
}

export default About;