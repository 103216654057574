import React from "react";
import tugas1 from '../asset/tugas1.png';
import tugas2 from '../asset/tugas2.png';
import tugas4 from '../asset/tugas4.png';

const Projects = () => {
    return (
        <section id="projects" className="bg-gray-900">
           <div className="container mx-auto text-center">
               <h2 className="text-2xl font-bold mb-8">My Projects</h2>
               <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                   <div className="bg-gray-800 p-4 rounded">
                   <img src={tugas1}alt="Samuel Wattimena" className="w-full h-48 object-covermb-4 rounded"/>
                   <h3 className="text-2xl font-bold mb-2">Gif Generator</h3>
                   <p className="text-gray-100">Gif Generator ReactJS</p>
                </div>
 
                <div className="bg-gray-800 p-4 rounded">
                   <img src={tugas2}alt="Samuel Wattimena" className="w-full h-48 object-covermb-4 rounded"/>
                   <h3 className="text-2xl font-bold mb-2">Profile UI </h3>
                   <p className="text-gray-100">Profile UI Figma</p>
                </div>

                <div className="bg-gray-800 p-4 rounded">
                   <img src={tugas4}alt="Samuel Wattimena" className="w-full h-48 object-covermb-4 rounded"/>
                   <h3 className="text-2xl font-bold mb-2">Music Web</h3>
                   <p className="text-gray-100">Figma Music Web UI</p>
                </div>
            </div>
            </div>
        </section>

    )

}

export default Projects;