import React from "react";

const Navbar = () => {
    return(
    <nav className="bg-gray-900 p-4">
        <div className="container mx-auto flex justify-between intems-center">
            <h1 className="text-white text-3xl front-bold">Evanezar Fa'iq</h1>
            <div>
                <a href="#about" className="text-gray-300 hover:tex-white mx-4 transition duration-300">About</a>
                <a href="#projects" className="text-gray-300 hover:tex-white mx-4 transition duration-300">Projects</a>
                <a href="#contact" className="text-gray-300 hover:tex-white mx-4 transition duration-300">Contact</a>
            </div>
        </div>
    </nav>
    )
}
export default Navbar;