import React from "react";

const Contact = () => {
    return(
        <section id="contact" className="py-20 bg-gray-900">
            <div className="container mx-auto px-6 text-center">
                <h2 className="text-4xl dront-bold mb-8 text-gray-50">Contact Me</h2>
                <p className="text-xl mb-4 text-gray-300">
                </p>
                <a href="mailto:evanezarfaiq@gmail.com" className="bg-gray-950 hover:bg-gray-400 text-white font-bold py-2 rounded-lg shadow-md transition duration-300">
                   Send Mail
                </a>
            </div>

        </section>
    )
}

export default Contact;